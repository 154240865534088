@import '~@studyportals/styles-abstracts/abstracts.scss';
@import '~@studyportals/styles-components/components/TextInput.scss';

.QuestionnaireLoading {
	padding: 2rem;
	height: 30rem;
	box-sizing: border-box;
}

.ModalOverlay .ModalWrapper.QuestionnairePopup {
	.ContentWrapper {
		padding: 0;

		@include Breakpoint(Large, ExtraLarge) {
			overflow: hidden;
		}
	}

	.CloseButton {
		z-index: $FirstFloor;
		@include MarginAreas(1.5, 1, 0, 0);

		.Icon {
			font-size: 1.2rem;
			color: $Green;
		}
	}
}

.CloseQuestionnaireButton {
	position: absolute;
	top: 1rem;
	right: 1rem;

	color: $White;
	font-size: 1.5rem;

	&:hover {
		cursor: pointer;
	}
}
